import { configureStore } from '@reduxjs/toolkit'
import userReducer from './users/user.reducer';
import messagesReducer from './messages/messages.reducer';
import alertReducer from './alert/alert.reducer';
import chatbotSettingsReducer from './chatbot_settings/chatbot_settings.reducer';
import appSettingsReducer from './app_settings/app_settings.reducer';


const store = configureStore({
    reducer: {
        alertReducer,
        userReducer,
        messagesReducer,
        chatbotSettingsReducer,
        appSettingsReducer
    }
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
