
import { setAlert } from '../alert/alert.actions'

export const fetchStart = () => ({
    type: "FETCH_START_SETTINGS"
});

export const fetchFailure = (errorMessage: any) => ({
    type: "FETCH_FAILURE_SETTINGS",
    payload: errorMessage
});

export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_SETTINGS"
});

export const setCurrentMessage = (errorMessage: any) => ({
    type: "SET_CURRENT_SETTINGS",
    payload: errorMessage
});

const getAppSettings = () => async (dispatch: any, getState: any) => {

    dispatch(fetchStart());

    try {
        const url = `/api/app_settings/`;
        //console.log(url);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET'
        });

        console.log("getting data...");
        const res = await response.json();
        //console.log(res);

        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error));
    }
}

const updateWelcomeMessage = (id: any, welcomeMessage: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    console.log("updateWelcomeMessage");
    const data = {
        welcomeMessage: welcomeMessage
    }

    dispatch(fetchStart());

    try {
        const url = `/api/app_settings/updateWelcomeMessage/${id}`;
        console.log(url);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(data)
        });

        const res = await response.json();
        dispatch(fetchSuccess());
        console.log(res);
        if (res.detail) throw res.detail;
        if (res) {
            dispatch(setAlert({ msg: "Welcome message updated", type: "success" }));
        }
        return true;
    } catch (error: any) {
        dispatch(fetchFailure(error));
        console.log(error);
        dispatch(setAlert({ msg: error, type: "error" }));
    }
    return false;
}


const updateInitialMessage = (id: any, initialMessage: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    console.log("updateWelcomeMessage");
    const data = {
        initialMessage: initialMessage,
        "status": "active"
    }

    dispatch(fetchStart());

    try {
        const url = `/api/app_settings/updateInitialMessage/${id}`;
        console.log(url);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(data)
        });

        const res = await response.json();
        dispatch(fetchSuccess());
        console.log(res);
        if (res.detail) throw res.detail;
        if (res) {
            dispatch(setAlert({ msg: "Welcome message updated", type: "success" }));
        }
        return true;
    } catch (error: any) {
        dispatch(fetchFailure(error));
        console.log(error);
        dispatch(setAlert({ msg: error, type: "error" }));
    }
    return false;
}

const messagesActions = {
    getAppSettings,
    updateWelcomeMessage,
    updateInitialMessage
}
export default messagesActions;