import React from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type Props = {
    onChange: any,
    value: string,
    maxCharacters?: number
}

const InputEditor = ({ onChange, value, maxCharacters = 1000 }:Props) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            config={
                {
                    // toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'], 
                }
            }
            data={value}
            onReady={(editor:any) => {
                // You can store the "editor" and use when it is needed.
                if (editor) {
                    if (value === null || value === undefined) {
                        value = "";
                    }
                    console.log('Editor is ready to use!', editor);
                    editor.setData(value);
                }
            }}
            onChange={onChange}
        />
    )
}

export default InputEditor