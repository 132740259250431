import React from 'react';
/* import UIkit from 'uikit'; */
import { useLocation, useNavigate } from "react-router-dom";

import { alpha, Box, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

const ListItemDrawer = (props: any) => {
    return (<ListItemButton
        {...props}
        sx={{
            margin: 1,
            width: "initial",
            borderRadius: 16,

            color: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.8),
            "& > .MuiListItemIcon-root": {
                color: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.8),
            },
            "&:hover": {
                backgroundColor: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.20),
            },
            "&.Mui-selected": {
                color: (theme: any) => theme.palette.getContrastText(theme.palette.secondary.main),
                backgroundColor: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.20),
            },
            "&.Mui-selected > .MuiListItemIcon-root": {
                color: (theme: any) => theme.palette.getContrastText(theme.palette.secondary.main),
            },
            "&.Mui-selected:hover": {
                /* color: (theme: any) => theme.palette.primary.main, */
                backgroundColor: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.25),
            },

        }}
    >
        {props.children}
    </ListItemButton>
    )
}

type Props = {
    openDrawer: boolean,
    setOpenDrawer: any,
    isMobile: boolean,
    drawerWidth: number
}

const menu = [
    {
        title: "App settings",
        icon: "settings",
        link: "/admin/access_settings",
    },
    {
        title: "Chatbot settings",
        icon: "chat",
        link: "/admin/chatbot_settings",
    }
]

const Sidebar = ({ openDrawer, setOpenDrawer, isMobile, drawerWidth }: Props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const onClickCloseMenu = (link: string) => (e: any) => {
        e.preventDefault();
        if (isMobile) {
            setOpenDrawer(false);
        }
        navigate(link);
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: (theme: any) => theme.palette.secondary.main,
                },
            }}
            {...(!isMobile && { variant: "persistent" })}
            anchor="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}

            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 3,
                marginBottom: 1,
                padding: 1,
                height: 20,
                // necessary for content to be below app bar
                //...theme.mixins.toolbar,
                justifyContent: 'center',
            }}>
                <Typography
                    variant='h4'
                    sx={{ color: (theme: any) => alpha(theme.palette.getContrastText(theme.palette.secondary.main), 0.8), }}
                >Chatbot App</Typography>
            </Box>

            <List>
                {menu.map((route: any, key: number) => (
                    (
                        <Box key={key}>
                            <ListItemDrawer
                                onClick={onClickCloseMenu(route.link)}
                                {...(location.pathname === route.link && { selected: true })}
                            >
                                <ListItemIcon >
                                    <Icon>{route.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={route.title} />
                            </ListItemDrawer>
                        </Box>
                    )))}
            </List>
        </Drawer>
    )
}

export default Sidebar;