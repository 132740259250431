import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import MainContent from '../components/MainContent';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 320;

const DashboardPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    
    if(!isMobile){
      setOpenDrawer(true)
    }
  }, [])
  
  return (
    <Box>
      <Header drawerOpen={!isMobile ? openDrawer : false}
        setOpenDrawer={setOpenDrawer} drawerWidth={drawerWidth}
      />
      <Sidebar openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer} isMobile={isMobile} drawerWidth={drawerWidth}
      />
      <MainContent openDrawer={!isMobile ? openDrawer : false}
        isMobile={isMobile} drawerWidth={drawerWidth}
      />
    </Box>
  )
}

export default DashboardPage