import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import geeAvatar from "../assets/img/profile.jpeg";

type AppProps = {
	sender: string;
	message: string;
	side: string;
	time: string;
}

// #579ffb blue #FFB6D5 pink

// hex color pink
const rightBackgroundColor = "#579ffb";
const rightColor = "#fff";
const leftBackgroundColor = "#FFB6D5";
const leftColor = "#000";

const ChatBubbleComponent = ({ sender, message, side, time }: AppProps) => {
	return (
		<Box display={"flex"} flexDirection={side === "left" ? "row" : "row-reverse"}>
			<Box display={"flex"} alignItems={"end"}
				flexDirection={side === "left" ? "row-reverse" : "row"}
			>
				<Box sx={{
					m: 1,
					p: 2,
					color: side === "left" ? leftColor : rightColor,
					backgroundColor: side === "left" ? leftBackgroundColor : rightBackgroundColor,
					borderRadius: "15px",
					borderBottomLeftRadius: side === "left" ? 0 : "15px",
					borderBottomRightRadius: side === "right" ? 0 : "15px",
					alignItems: "center"
				}}>
					<Box
						sx={{
							display: "block",
						}}>
						<Box display="flex">

							<Typography fontWeight={"fontWeightBold"} flexGrow={1} sx={{ pr: 1, pb: 0.5 }}>
								{sender}
							</Typography>
							<Typography>
								{time}
							</Typography>
						</Box>
						<Box >
							<Typography variant="body1">
								{message}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{
					mb: 1,
				}}>
					{side === "right" ? <Avatar><PersonIcon /></Avatar> : <Avatar src={geeAvatar} />}
				</Box>
			</Box>
		</Box >
	);
};

export default ChatBubbleComponent;
