
import { useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import {
  redirect,
} from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';

import { Button, Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import InputEditor from '../../components/InputEditor'

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import settingsActions from '../../redux/app_settings/app_settings.actions';
import userActions from '../../redux/users/user.actions';
import { Box } from '@mui/system';

const AccessConfigPage = () => {

  const [settings, setSettings] = useState({
    _id: { $oid: "" },
    welcomeMessage: ""
  })
  const dispatch = useAppDispatch();
  const userReducer = useAppSelector((state: any) => state.userReducer);

  useEffect(() => {
    console.log("React working...");
    if (userReducer.currentUser) {
      const token = userReducer.currentUser.token;
      let decodedToken: any = jwt_decode(token);
      let dateNow = new Date();
      let tokenExp = new Date(decodedToken.exp);
      if (tokenExp.getTime() < Math.round(dateNow.getTime() / 1000)) {
        dispatch(userActions.logout());
        redirect("")
      }
    }
  }, [])
  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const result = await dispatch(settingsActions.getAppSettings());
    setSettings(result)
  }

  const onClickSaveWelcomeMessage = async () => {
    console.log("save welcome message")
    console.log(settings._id.$oid)
    const result = await dispatch(settingsActions.updateWelcomeMessage(settings._id.$oid, settings.welcomeMessage));
    console.log(result)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} pb={4}>
          <Typography variant="h5" fontWeight={600} >Application settings</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6" fontWeight={600}>Welcome message</Typography>
        </Grid>
        <Grid xs={12}>
          <InputEditor
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setSettings({ ...settings, welcomeMessage: data });
              //console.log({ event, editor, data });
            }}
            value={settings.welcomeMessage}
          />
        </Grid>
        <Grid xs={12} sx={{ pb: 3 }} textAlign="right">
          <Button variant="contained" onClick={onClickSaveWelcomeMessage}>update welcome message</Button>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6" fontWeight={600}>Application password</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1">
            To be confirmed depending on the application's security requirements.
          </Typography>
          <QRCodeSVG value={`${process.env.REACT_APP_FRONTEND_URL}/proxy/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ik5HViBDSGF0Ym90IiwiaWF0IjoxNTE2MjM5MDIyfQ.j93d2jV5GTgozZMQ3KFpqc-dMOlCZYEpzvWEt2HCBdE`} />
        </Grid>
      </Grid>
    </>
  )
}

export default AccessConfigPage