const INITIAL_STATE = {
    currentUser: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem("currentUser")!) : {},
    isAuthenticated: false,
    users: [],
    loading: false,
    errorMessage: undefined
  };
  
  const userReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case "FETCH_START_USER":
        return {
          ...state,
          loading: true
        };
      case "SET_CURRENT_USER":
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        return {
          ...state,
          currentUser: action.payload,
          loading: false
        };
      case "LOGIN_SUCCESS":
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        return {
          ...state,
          currentUser: action.payload,
          isAuthenticated: true,
          loading: false
        };
      case "FETCH_FAILURE_USER":
        return {
          ...state,
          errorMessage: action.payload,
          isAuthenticated: false,
          loading: false
        };
      case "FETCH_SUCCESS_USER":
        return {
          ...state,
          loading: false
        };
      case "LOGOUT":
        localStorage.removeItem('currentUser');
        return {
          ...state,
          currentUser: null,
          isAuthenticated: false,
          loading: false
        };
      default:
        return state;
    }
  };
  
  export default userReducer;