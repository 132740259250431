import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from "react-cookie";
import {
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from 'react-router-dom';

import { useAppSelector } from './redux/hooks';

import ChatPage from "./pages/ChatPage";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";

import SnackBar from './components/Snackbar';

import ChatbotConfigPage from "./pages/AdminPage/ChatbotConfigPage";
import AccessConfigPage from "./pages/AdminPage/AccessConfigPage";
import ProxyPage from "./pages/ProxyPage";

type AppProps = {
  isLogged: boolean;
  redirectPath?: string;
}

const ProtectedRoute = ({ isLogged, redirectPath = '/' }: AppProps) => {
  if (!isLogged) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

let theme = createTheme({
  palette: {
    primary: {
      main: "#3866e2",
    },
    secondary: {
      main: "#0F172A",
    },
  },
  shape: {
    borderRadius: 5
  },
});

const App = () => {

  const userReducer = useAppSelector((state: any) => state.userReducer);

  const [cookies, setCookie] = useCookies(["userId"]);

  useEffect(() => {
    //console.log(cookies)
    if (cookies.userId === undefined) {
      const newId = uuidv4();
      console.log(newId)
      setCookie("userId", newId, {
        path: "/"
      });
    } else {
      console.log("Device identified: " + cookies.userId)
    }
  }, [])


  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={createBrowserRouter(
          createRoutesFromElements(
            <>
              <Route index
                element={<ChatPage />}
              /* errorElement={<Typography>Something went wrong. Please try again later.</Typography>} */
              />
              <Route path="login" element={<LoginPage />} />
              <Route path="proxy/:access_token" element={<ProxyPage />} />
              <Route element={<ProtectedRoute isLogged={userReducer.currentUser ? true : false} />}>
                <Route path="admin" element={<AdminPage />}>
                  <Route path="chatbot_settings" element={<ChatbotConfigPage />} />
                  <Route path="access_settings" element={<AccessConfigPage />} />
                </Route>
              </Route>
            </>
          )
        )} />
        <SnackBar />
      </ThemeProvider>
    </>
  );
};

export default App;
