import { Dialog, DialogContent, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'

type Props = {
    showModal: boolean,
    setShowModal: any
}

const DialogWrongPassword = ({ showModal, setShowModal }: Props) => {

    const handleClose = (event: any, reason: string) => {
        if (reason && reason == "backdropClick")
            return;
        setShowModal(false);
    }

    return (
        <Dialog open={showModal} onClose={handleClose} aria-labelledby="" maxWidth="sm" fullWidth disableEscapeKeyDown>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h4" color="initial">Sorry</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="body1" color="initial">This link is not valid. Please get the latest QR code to access this service.</Typography>
                        {/* <Typography variant="body1" color="initial">This link is not valid for today. Please get the latest link to access this service.</Typography> */}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DialogWrongPassword