import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Box, Container, Paper, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import { useParams, useNavigate } from 'react-router-dom';
import usersActions from '../redux/users/user.actions';
import { useCookies } from "react-cookie";
import { useAppDispatch } from "../redux/hooks";

type Params = {
    access_token: string;
}
const ProxyPage = () => {
    const [tokenValue, setTokenValue] = useState({
        token: false,
        loaded: false,
        result: ""
    });


    const theme = useTheme();
    const { access_token } = useParams<Params>();
    const [cookiesToken, setCookieToken, removeCookieToken] = useCookies(["ngv_token_cookie"]);
    const [cookies, setCookie] = useCookies(["userId"]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (!tokenValue.loaded) return;
        console.log(tokenValue)
        if (tokenValue.result == "success") {
            setCookieToken("ngv_token_cookie", tokenValue.token, {
                path: "/"
            });
            console.log("cookie created")
            navigate("/");
        } else /* if (tokenValue.result == "wrong_token")  */ {
            removeCookieToken("ngv_token_cookie", {
                path: "/"
            });
            console.log("cookie removed")
            navigate("/");

        }
    }, [tokenValue]);

    const getData = async () => {
        console.log("Proxy result")
        console.log(cookies.userId, access_token)

        let userId = cookies.userId;
        if (userId === undefined) {
            userId = uuidv4();
            console.log(userId)
            setCookie("userId", userId, {
                path: "/"
            });
        }
        const result = await dispatch(usersActions.generateAccessToken(userId, access_token!));
        console.log(result)
        setTokenValue({ ...result, loaded: true })
    }


    return (
        <Box
            sx={{
                backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
                height: "100vh"
            }}>
            <Container maxWidth="sm" sx={{ p: 10 }}>
                <Paper sx={{ backgroundImage: "" }} >
                    <Box
                        overflow={"auto"}
                        sx={{
                            p: 2,
                        }}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1">Loading...</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Box >

    );
};

export default ProxyPage;
