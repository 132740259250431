import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {
    Avatar, Badge, IconButton, ListItemIcon, Menu,
    MenuItem, Switch, Divider, FormControlLabel,
    useScrollTrigger, Box, Hidden, Tooltip, ListItemText, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { red } from '@mui/material/colors';

import userActions from '../redux/users/user.actions';
import { useAppSelector, useAppDispatch } from '../redux/hooks';

type DrawerType = {
    theme?: any,
    open: boolean,
    drawerwidth: number,
}
const DrawerAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerwidth }: DrawerType) => ({

    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerwidth,
    }),
}));

type HeaderProps = {
    drawerOpen: boolean,
    setOpenDrawer: any,
    drawerWidth: number
}

const Header = ({ drawerOpen, setOpenDrawer ,drawerWidth}: HeaderProps) => {


    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClickLogout = async (event: any) => {
        dispatch(userActions.logout());
        navigate("/login");
    }

    return (
        <>
            <DrawerAppBar position="fixed" open={drawerOpen} drawerwidth={drawerWidth} color="transparent">
                <Toolbar>
                    <Box flexGrow={1}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpenDrawer(!drawerOpen)}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                    </Box>
                    <Button variant="outlined" onClick={onClickLogout}>log out</Button>
                </Toolbar>
            </DrawerAppBar>

        </>
    )
}
export default Header;