import React, { useState, useEffect } from "react";
import { format as timeFormat } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";

import { Box, Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks';

import ChatBubbleComponent from "../components/ChatBubbleComponent";
import DialogWrongPassword from "../components/DialogWrongPassword";

import messagesActions from '../redux/messages/messages.actions';
import settingsActions from '../redux/app_settings/app_settings.actions';
import { MyToken } from "../types/MyToken";

type Params = {
  password?: string;
};

type TypeMessage = {
  sender: string;
  message: string;
  side: string;
  time: string;
}

const ChatPage = () => {
  const [messages, setMessages] = useState<TypeMessage[]>([]);
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const [message, setMessage] = useState("");
  const [showWrongPasswordModal, setShowWrongPasswordModal] = useState(false)

  // useParams as string typescript

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [cookies, setCookie] = useCookies(["userId"]);
  const [cookiesToken, setCookieToken] = useCookies(["ngv_token_cookie"]);

  const dispatch = useAppDispatch()
  const loading = useAppSelector((state: any) => state.messagesReducer.loading);

  useEffect(() => {
    getData();
    console.log("cookiesToken.ngv_token_cookie")
    console.log(cookiesToken)

    if (!cookiesToken.ngv_token_cookie) {
      console.log("Showing error modal")
      setShowWrongPasswordModal(true);
    } else {
      let decodedToken = jwt_decode<MyToken>(cookiesToken.ngv_token_cookie);
      let dateNow = new Date();
      let tokenExp = new Date(decodedToken.exp);
      if (tokenExp.getTime() < Math.round(dateNow.getTime() / 1000)) {
        setShowWrongPasswordModal(true);
      }
    }
  }, [])

  const getData = async () => {
    const result = await dispatch(settingsActions.getAppSettings());

    const currentTime = timeFormat(new Date(), 'HH:mm');
    if (result.initialMessage) {
      setMessages([{
        sender: "Gee",
        message: result.initialMessage,
        side: "left",
        time: currentTime,
      }])
    }
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setMessage(value);
  };

  const onClickSendMessage = async () => {
    if (message === "" || showWrongPasswordModal) return;

    setMessage("");
    const currentTime = timeFormat(new Date(), 'HH:mm');
    const userMessage = {
      sender: "You",
      message: message,
      side: "right",
      time: currentTime
    }
    const newBlockOfMessages = [...messages, userMessage];
    const loadingMessage = { sender: "Gee", message: "...", side: "left", time: currentTime };
    setMessages([...newBlockOfMessages, loadingMessage]);

    const history = []
    for (let index = 0; index < messages.length; index++) {
      history.push(messages[index].message)
    }
    const response = await dispatch(messagesActions.getMessageResponse(cookiesToken.ngv_token_cookie, cookies.userId, message, history));

    if (response.message) {
      const newMessage = {
        sender: "Gee",
        message: response.message,
        side: "left",
        time: currentTime
      }
      setMessages([...newBlockOfMessages, newMessage]);
    } else {
      const newMessage = {
        sender: "Gee",
        message: "Sorry, I didn't understand that. Can you try again?",
        side: "left",
        time: currentTime
      }
      setMessages([...newBlockOfMessages, newMessage]);
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && !loading) {
      onClickSendMessage()
    }
  }

  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        height: "100vh"
      }}>
      <Container maxWidth="md" sx={{ p: 2 }}>
        <Paper sx={{ backgroundImage: `url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")` }} >
          <Box sx={{
            backgroundColor: "#f7b9de",
            px: 2,
            py: 1,
          }} textAlign="center">
            <Typography variant="body1" color="initial">♥ Gee ♥</Typography>
          </Box>
          <Box
            overflow={"auto"}
            sx={{
              p: 1.5,
              height: isMobile ? "calc(100vh - 190px)" : "calc(100vh - 180px)"
            }}>
            {messages.map((item: any, index: number) => (
              <Box key={index}>
                <ChatBubbleComponent sender={item.sender} message={item.message} side={item.side} time={item.time} />
              </Box>
            ))}
            <Box ref={messagesEndRef} />
          </Box>
          <Box display={"flex"}
            alignItems="center"
            sx={{
              backgroundColor: "#eee",
              p: 2,
            }}
          >
            <TextField
              /* size={isMobile ? "small" : "medium"} */
              size="small"
              variant="outlined"
              placeholder="Enter your message..."
              fullWidth
              onChange={handleChange}
              value={message}
              onKeyPress={handleKeyPress}
            />
            <Box ml={2}>
              <Button
                size={isMobile ? "medium" : "large"}
                sx={{ backgroundColor: "#D94496", color: "white" }}
                onClick={onClickSendMessage}
                variant="contained"
                disabled={loading}>Send</Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <DialogWrongPassword setShowModal={setShowWrongPasswordModal} showModal={showWrongPasswordModal} />
    </Box >

  );
};

export default ChatPage;
