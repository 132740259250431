const INITIAL_STATE = {
    settings: {},
    loading: false,
    errorMessage: undefined
  };
  
  const settingsReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case "FETCH_START_CHATBOT_SETTINGS":
        return {
          ...state,
          loading: true
        };
      case "FETCH_SUCCESS_CHATBOT_SETTINGS":
        return {
          ...state,
          loading: false
        };
      case "GET_CHATBOT_SETTINGS":
        return {
          ...state,
          settings: action.payload,
          loading: false
        };
      case "FETCH_FAILURE_CHATBOT_SETTINGS":
        return {
          ...state,
          errorMessage: action.payload,
          loading: false
        };
      default:
        return state;
    }
  };
  
  export default settingsReducer;