
import { useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import { Button, Divider, MenuItem, Select, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import {
  redirect,
} from 'react-router-dom';

import InputEditor from '../../components/InputEditor'

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import appsettingsActions from '../../redux/app_settings/app_settings.actions';
import settingsActions from '../../redux/chatbot_settings/chatbot_settings.actions';
import userActions from '../../redux/users/user.actions';
import { Box } from '@mui/system';

const AccessConfigPage = () => {

  const [initialChatbotMessage, setInitialChatbotMessage] = useState("")
  const [appSettings, setAppSettings] = useState({
    _id: { $oid: "" },
    initialMessage: ""
  })
  const [settings, setSettings] = useState([])

  const userReducer = useAppSelector((state: any) => state.userReducer);

  useEffect(() => {
    console.log("React working...");
    if (userReducer.currentUser) {
      const token = userReducer.currentUser.token;
      let decodedToken: any = jwt_decode(token);
      let dateNow = new Date();
      let tokenExp = new Date(decodedToken.exp);
      if (tokenExp.getTime() < Math.round(dateNow.getTime() / 1000)) {
        dispatch(userActions.logout());
        redirect("")
      }
    }
  }, [])

  const [selectedFile, setSelectedFile] = useState({
    _id: { $oid: "" },
    fileName: "",
    fileContent: "",
  })
  const dispatch = useAppDispatch();

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const result = await dispatch(settingsActions.getChatbotSettings());
    console.log(result)
    setSettings(result)

    const result2 = await dispatch(appsettingsActions.getAppSettings());
    if (result2) {
      setAppSettings(result2)
    }
    console.log(result2)
  }


  const handleChange = (event: any) => {
    event.preventDefault();
    const file: any = settings.find((item: any) => item._id.$oid === event.target.value);
    console.log(file)
    setSelectedFile(file);
  };


  const handleChangeInitialMessage = (event: any) => {

    setAppSettings({ ...appSettings, initialMessage: event.target.value });
  };

  const onClickSaveInitialMessage = async () => {
    console.log("save welcome message")
    console.log(appSettings._id.$oid)
    const result = await dispatch(appsettingsActions.updateInitialMessage(appSettings._id.$oid, appSettings.initialMessage));
    console.log(result)
  }

  console.log(appSettings)
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h5" fontWeight={600}>Chatbot settings</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6" fontWeight={600}>Initial message</Typography>
        </Grid>
        <Grid xs={12} sx={{ pb: 3 }} >
          <Typography sx={{ pb: 1 }} variant="body1">Please enter the initial message for the chatbot</Typography>
          <TextField onChange={handleChangeInitialMessage} fullWidth value={appSettings.initialMessage} />
        </Grid>
        <Grid xs={12} sx={{ pb: 3 }} textAlign="right">
          <Button variant="contained" onClick={onClickSaveInitialMessage}>update welcome message</Button>
        </Grid>
        <Grid xs={12}>
          <Typography variant="h6" fontWeight={600}>Files</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography sx={{ pb: 1 }} variant="body1">Select a file</Typography>
          <Select
            fullWidth
            value={selectedFile._id ? selectedFile._id.$oid : ''}
            onChange={handleChange}
          >

            {settings.length > 0 && settings.map((item: any, index: number) => (
              <MenuItem key={index} value={item._id.$oid}>{item.fileName}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12} textAlign="right">
          {selectedFile._id.$oid !== "" && <FileItem selectedFile={selectedFile} />}
        </Grid>
      </Grid>
    </>
  )
}


type FileItemProps = {
  selectedFile: {
    _id: { $oid: string },
    fileName: string,
    fileContent: string,
  },
}

const FileItem = ({ selectedFile }: FileItemProps) => {

  useEffect(() => {
    setValues(selectedFile);
  }, [selectedFile])


  const [values, setValues] = useState({
    fileContent: ""
  })

  const dispatch = useAppDispatch();

  const onClickSave = async () => {
    console.log("onClickSave")

    const body = {
      fileName: selectedFile.fileName,
      fileContent: values.fileContent
    }
    const result = await dispatch(settingsActions.updateFile(selectedFile._id.$oid, body));
    console.log(result)
  }

  const handleChange = (event: any) => {
    console.log("event.target.value")
    setValues({ ...values, fileContent: event.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <TextField
          multiline={true}
          rows={20}
          name="fileContent"
          onChange={handleChange}
          hiddenLabel
          variant='filled'
          fullWidth
          value={values.fileContent}
        />
      </Grid>
      <Grid xs={12}>
        <Button variant="contained" onClick={onClickSave}>update file</Button>
      </Grid>
    </Grid>
  )
}

export default AccessConfigPage