
export const fetchStart = () => ({
    type: "FETCH_START_MESSAGES"
});

export const fetchFailure = (errorMessage: any) => ({
    type: "FETCH_FAILURE_MESSAGES",
    payload: errorMessage
});

export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_MESSAGES"
});

export const setCurrentMessage = (errorMessage: any) => ({
    type: "SET_CURRENT_MESSAGES",
    payload: errorMessage
});

const getMessageResponse = (token: string, userId: string, message: string, history: any[]) => async (dispatch: any, getState: any) => {
    console.log("getMessage");
    const state = getState();

    const body = {
        userId: userId,
        message: message,
        history: history
    }
    console.log(body);
    dispatch(fetchStart());

    try {
        const url = `/api/messages/`;
        console.log(url);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });

        console.log("getting data...");
        const res = await response.json();
        console.log(res);

        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error));
        return false;
    }
}


const messagesActions = {
    getMessageResponse,
}
export default messagesActions;