
type Props = {
    msg: string,
    type?: string
}

export const setAlert = ({ msg, type = "normal" }: Props) => (dispatch: any) => {
    console.log("open alert");
    dispatch({
        type: "OPEN_ALERT",
        payload: { msg, type }
    })
};

export const closeAlert = () => (dispatch: any) => {
    dispatch({
        type: "CLOSE_ALERT",
    })
};
