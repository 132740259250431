const INITIAL_STATE = {
    messages: [],
    message: {},
    loading: false,
    errorMessage: undefined
  };
  
  const messagesReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case "FETCH_START_MESSAGES":
        return {
          ...state,
          loading: true
        };
      case "FETCH_SUCCESS_MESSAGES":
        return {
          ...state,
          loading: false
        };
      case "GET_MESSAGES":
        return {
          ...state,
          messages: action.payload,
          loading: false
        };
      case "GET_MESSAGE":
        return {
          ...state,
          message: action.payload,
          loading: false
        };
      case "FETCH_FAILURE_MESSAGES":
        return {
          ...state,
          errorMessage: action.payload,
          loading: false
        };
      case "RESET_MESSAGES":
        return {
          ...state,
          messages: [],
          loading: true
        };
  
      default:
        return state;
    }
  };
  
  export default messagesReducer;