import { setAlert } from '../alert/alert.actions'
import jwt_decode from "jwt-decode";

export const fetchStart = () => ({
    type: "FETCH_START_USER"
});

export const fetchFailure = (errorMessage: any) => ({
    type: "FETCH_FAILURE_USER",
    payload: errorMessage
});

export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_USER"
});

export const setCurrentUser = (errorMessage: any) => ({
    type: "SET_CURRENT_USER",
    payload: errorMessage
});

type Credentials = {
    email: string,
    password: string
}
const login = (credentials: Credentials) => async (dispatch: any) => {
    const { email, password } = credentials;
    dispatch(fetchStart());

    const body = {
        "email": email,
        "password": password
    };
    console.log(body);
    const url = `/api/users/login`;
    console.log(url);
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        console.log(res);
        dispatch(fetchSuccess());
        if (res.token) {
            const result = res;
            let decodedToken: any = jwt_decode(result.token);
            console.log(decodedToken);
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: {
                    id: decodedToken.sub.id,
                    email: decodedToken.sub.email,
                    token: res.token,
                }
            });
            return res;
        } else {
            dispatch(setAlert({ msg: res.detail }));
        }
    } catch (error: any) {
        console.log(error.message);
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Usuario y/o contraseña no coinciden." }));
    }
};
const logout = () => async (dispatch: any) => {
    dispatch({
        type: 'LOGOUT'
    });
};


const generateAccessToken = (deviceId: string, token: string) => async (dispatch: any) => {
    dispatch(fetchStart());

    const body = {
        "access_token": token,
        "deviceId": deviceId,
    };
    console.log(body);
    const url = `/api/auth/generateAccessToken`;
    console.log(url);
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        return res

    } catch (error: any) {
        console.log(error.message);

    }
};

const userActions = {
    login,
    logout,
    setCurrentUser,
    generateAccessToken
}
export default userActions;