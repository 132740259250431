import React from 'react'

import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';


type MainProps = {
    theme?: any,
    open: boolean,
    drawerwidth: number,
}

const Main = styled(Container, {
    shouldForwardProp: (prop) => prop !== 'button',
})(({ theme, open, drawerwidth }: MainProps) => ({
    padding: "84px 0px 24px",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerwidth,
    }),
}));

type Props = {
    openDrawer: boolean,
    isMobile: boolean,
    drawerWidth: number,
    children?: any
}

const MainContent = ({ openDrawer, isMobile, children, drawerWidth }: Props) => {
    return (
        <Main /* maxWidth="xl" */
            /* component="main" */
            drawerwidth={drawerWidth}
            open={openDrawer}
        >
            {/* {children} */}
            <Outlet />
        </Main>
    )
}

export default MainContent