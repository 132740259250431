import React, { useState, useEffect } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Icon } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from '../redux/hooks';
import userActions from '../redux/users/user.actions';

const LoginPage = () => {

    const [userCredentials, setUserCredentials] = useState({ email: "", password: "" });
    const [usersInfo, setUsersInfo] = useState([])
    const { email, password } = userCredentials;
    const [showOrganizations, setShowOrganizations] = useState(false);
    const [showInactiveModal, setShowInactiveModal] = useState(false);

    const loading = useAppSelector((state: any) => state.userReducer.loading);
    const userReducer = useAppSelector((state: any) => state.userReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (userReducer.currentUser) {
            navigate("/admin/access_settings");
        }
    }, [userReducer.currentUser]);

    const onClickLogin = async () => {
        let flagWhites = true;
        let flagGeneral = true;

        if (password === "" || email === "") {
            flagWhites = false;
        }

        if (!flagWhites) {
            flagGeneral = false;

        }
        if (flagGeneral) {
            const data: any = await dispatch(userActions.login({ email, password }))
            if (data) {
                console.log("navigate to dashboard")
                //navigate("/admin");
            }
        }
    }
    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setUserCredentials({ ...userCredentials, [name]: value })
    }

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            onClickLogin()
        }
    }

    return (
        <Grid container component="main" sx={{
            height: '100vh'
        }}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7}
                sx={{
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <Icon>lock</Icon>
                    </Avatar>
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <Box pt={1}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={onClickLogin}
                            disabled={loading}
                        >Continue</Button>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    );
}
export default LoginPage;